const SiteSettings = {
	// API endpoints
	// These can be adjusted to match custom controllers which implement base classes
	Endpoints: {
		// the wish list surface controller end point
		Newsletter: '/umbraco/surface/Newsletter/',
	},

	// Notifications - the notification bar
	Notifications: {
		// If true messages will be inserted into the notification bar
		enabled: true,
	},
};

export default SiteSettings;
