import serialize from 'form-serialize';
import SiteSettings from '../../settings/sitesettings';
import Notifications from '../notifications/notifications';

class Newsletter {
	constructor() {
		this.newsletterForm = document.querySelector("[data-nform='newsletter']");

		if (this.newsletterForm) {
			this.init();
		}
	}

	init() {
		this.bindSubmit();
	}

	bindSubmit() {
		this.newsletterForm.addEventListener('submit', event => {
			event.preventDefault();

			fetch(`${SiteSettings.Endpoints.Newsletter}Subscribe`, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
					'X-Requested-With': 'XMLHttpRequest',
				},
				method: 'POST',
				credentials: 'include',
				body: serialize(this.newsletterForm),
			})
				.then(data => data.json())
				.then(result => {
					if (result.Success) {
						Notifications.success(result.Messages[0]);
						this.newsletterForm.innerHTML = `<h4>${result.Messages[0]}</h4>`;
					} else {
						Notifications.error(result.Messages[0]);
					}
				});
		});
	}
}

export default new Newsletter();
