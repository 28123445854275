class EventBrite {
	constructor() {
		this.eventBriteModalTriggers = Array.from(
			document.querySelectorAll('[data-nbutton="eb-ticket-modal-trigger"]')
		);

		if (this.eventBriteModalTriggers) {
			this.init();
		}
	}

	init() {
		this.eventBriteModalTriggers.forEach(triggerEl => {
			this.registerTrigger(triggerEl);
		});
	}

	registerTrigger(triggerEl) {
		window.EBWidgets.createWidget({
			widgetType: 'checkout',
			eventId: triggerEl.dataset.ebid,
			modal: true,
			modalTriggerElementId: `${triggerEl.id}`,
			onOrderComplete: this.orderComplete,
		});
	}

	orderComplete() {
		console.log('Order Completed!');
	}
}

export default new EventBrite();
