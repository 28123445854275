import { tns } from 'tiny-slider/src/tiny-slider.module.js';

class SponsorBannerCarousel {
	constructor() {
		this.carouselEl = document.querySelector('[data-ncomponent="sponsor-banner"]');

		if (this.carouselEl) {
			this.init();
		}
	}

	init() {
		this.carousel = tns({
			container: this.carouselEl,
			items: 1,
			slideBy: 1,
			autoplay: true,
			controls: false,
			autoplayButton: false,
			autoplayButtonOutput: false,
			autoplayHoverPause: true,
			nav: false,
			autoplayTimeout: 7000,
			// navContainer: document.querySelector('[data-ncomponent="hero-nav"]'),
			// controlsContainer: document.querySelector('[data-ncarouselnav="shows"]'),
			touch: true,
			mouseDrag: false,
		});
	}
}

export default new SponsorBannerCarousel();
