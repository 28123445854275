import ContentModuleGallery from './modules/galleries/cm-gallery';
import Sharer from './modules/share/sharer';
import SponsorBannerCarousel from './modules/carousels/sponsor-banner-carousel';
import AutoResizeVideos from './modules/videos/autoresize';
import EventBrite from './modules/tickets/eventbrite';
import ExhibitorCarousels from './modules/carousels/exhibitor-carousel';
import Newsletter from './modules/newsletter/newsletter';
import 'picturefill';

// Picture element HTML5 shiv
document.createElement('picture');
