const notificationMarkup = `<div class="notification" data-ncomponent="notifybar">
    <p class="notification__body" data-nelement="notifymessage">
    </p>
    <div class="notification__actions">
        <a href="#" data-nelement="notifyclose">
            <span class="visuallyhidden">Close notification</span>
            <svg class="icon"><use xlink:href="#close"></use></svg>
        </a>
    </div>
</div>`;

export default notificationMarkup;
