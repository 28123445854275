import StoreSettings from '../../settings/sitesettings';
import notificationMarkup from './notificationmarkup';

export class Notifications {
	constructor() {
		if (StoreSettings.Notifications.enabled) {
			this.init();
		}
	}

	init() {
		this.types = ['success', 'info', 'error', 'warn'];

		this.notificationEl = document.querySelector('[data-ncomponent="notifybar"]');

		if (!this.notificationEl) {
			document.body.insertAdjacentHTML('beforeend', notificationMarkup);
			this.notificationEl = document.querySelector('[data-ncomponent="notifybar"]');
		}

		this.notificationMessageEl = document.querySelector('[data-nelement="notifymessage"]');
		this.notiticationCloseEl = document.querySelector('[data-nelement="notifyclose"]');
		this.notiticationCloseEl.addEventListener('click', e => this.dismiss(e));
	}

	success(msg) {
		this.message(msg, 'success', 2000);
	}

	info(msg) {
		this.message(msg, 'info', 2000);
	}

	error(msg) {
		this.message(msg, 'error', 2000);
	}

	warn(msg) {
		this.message(msg, 'warn', 2000);
	}

	message(message, type, delay = 2000) {
		this.notificationMessageEl.innerHTML = message;
		this.types.forEach(notificationType => {
			this.notificationEl.classList.remove(`notification--${notificationType}`);
		});
		this.notificationEl.classList.remove('notification--closed');
		this.notificationEl.classList.add('notification--open');
		this.notificationEl.classList.add(`notification--${type}`);
		// Clear the timeout handle if there is one, this means
		// notification animations don't get confused
		if (this.timeoutHandle) {
			clearTimeout(this.timeoutHandle);
		}
		this.timeoutHandle = setTimeout(() => {
			this.dismiss();
		}, delay);
	}

	dismiss(event) {
		if (event) {
			event.preventDefault();
		}
		this.notificationMessageEl.innerHTML = '';
		this.notificationEl.classList.remove('notification--open');
		this.notificationEl.classList.add('notification--closed');
	}
}

export default new Notifications();
